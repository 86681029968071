import Splide from "@splidejs/splide";

export default class TestimonialsSlider {
    constructor() {
        this.everyTestimonialsSlider = [];
        this.testimonialsSlider = [];
        this.events();
    }

    events() {
        window.addEventListener("DOMContentLoaded", () => {
            this.testimonialsSlider = document.querySelectorAll(".testimonials__slider");

            if (this.testimonialsSlider.length) {
                this.initializeSliders();
            }
        });
    }

    initializeSliders() {
        this.testimonialsSlider.forEach((item) => {
            this.everyTestimonialsSlider.push(
                new Splide(item, {
                    // type: 'loop',
                    pagination: false,
                    perPage: 3,
                    perMove: 1,
                    gap: 24,
                    arrows: false,
                    trimSpace: true,
                    interval: 1200,
                    autoplay: true,
                    breakpoints: {
                        1024: {
                            perPage: 2,
                        },
                        575: {
                            perPage: 1.25,
                            gap: 16
                        },
                    },
                }).mount()
            );
        });
    }
}

new TestimonialsSlider();